<template>
  <ul class="sidebar-menu md:inline-flex md:justify-evenly flex-column" :class="formatting">
    <li><a class="uppercase swipe" @click="deepLink" href="#hero">Info For Partners</a></li>
    <li :class="link1_state"><a class="uppercase swipe" @click="deepLink" href="#how-to-get-involved">How To Get Involved</a></li>
    <li :class="link2_state"><a class="uppercase swipe" @click="deepLink" href="#the-artist-directory">The Artist Directory</a></li>
    <li :class="link3_state"><a class="uppercase swipe" @click="deepLink" href="#join-the-movement">Join The Movement</a></li>
  </ul>
</template>

<script>
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)

export default {
  name: 'sidebarMenu',

  props: [
    'location', 'active'
  ],

  data () {
    return {
      formatting: '',
      link1_state: '',
      link2_state: '',
      link3_state: ''
    }
  },

  created () {
    const app = this
    window.addEventListener('load', function (event) {
      app.cards = document.querySelectorAll('.card')
    })
  },

  mounted () {
    switch (this.location) {
      case 'top' :
        this.formatting = 'self-center w-full mt-7vw md:mt-10vw'
        break
      case 'side' :
        this.formatting = 'flex-col'
        break
    }

    switch (this.active) {
      case '1' :
        this.link1_state = 'active'
        break
      case '2' :
        this.link2_state = 'active'
        break
      case '3' :
        this.link3_state = 'active'
        break
    }
  },

  methods: {
    deepLink (e) {
      const $el = e.target
      const href = $el.getAttribute('href')
      const sectionID = href.substring(1)
      let scrollOffset = 0

      if (window.innerWidth > 767) { // Desktop
        const extraPages = 2 // not sure why GSAP is calculating this additional height
        const relativeHeight = innerHeight * ((this.cards.length / 2) + extraPages) / (this.cards.length / 2)
        let cnt = 0

        e.preventDefault()

        for (const card of this.cards) {
          if (card.getAttribute('id') === sectionID) {
            scrollOffset = relativeHeight + (relativeHeight * (cnt / 2))
            break
          }
          cnt++
        }
      } else {
        scrollOffset = document.getElementById(sectionID).nextElementSibling.getBoundingClientRect().top
      }

      gsap.to(window, {
        scrollTo: scrollOffset,
        duration: 0.5,
        ease: 'ease-in-out'
      })
    }
  }
}
</script>
